import React, { ReactElement, ReactFragment } from "react";
import { Button, Grid, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import CustomDialog from "../Dialog/Dialog";
import "./Menu.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Check } from "@mui/icons-material";
import { Link } from "react-router-dom";

// Global variables
const ITEM_HEIGHT = 48;

export type listItemMenu = {
  icons?: Array<ReactElement>;
  label: string;
  onClick?: () => void;
  checked?: boolean;
  redirectTo?: { pathname: string; state: { [key: string]: string } };
};

const ListMenu = ({
  anchorEl,
  onRemoveAnchorEl,
  listItemMenu,
}: {
  anchorEl?: Element | ((element: Element) => Element) | null;
  onRemoveAnchorEl: () => void;
  listItemMenu?: Array<listItemMenu>;
}) => {
  // useState
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState<number | undefined>(undefined);

  // return
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onRemoveAnchorEl}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "max-content",
        },
      }}
    >
      {(listItemMenu || []).map((option, i) => (
        <MenuItem
          key={i}
          selected={selected === i}
          onClick={() => {
            setSelected(i);
            if (option.onClick && typeof option.onClick === "function") option.onClick();
          }}
        >
          {/* Link to */}
          {option.redirectTo ? (
            <Link
              className="remove-link-style"
              to={{
                pathname: option.redirectTo.pathname,
                state: option.redirectTo.state,
              }}
            >
              {option.checked && <div style={{ width: 24 }}>{selected === i && <Check />}</div>}
              <ListItemText>
                <Grid item container xs={12} alignContent="center">
                  {(option.icons || []).map((icon, i) => (
                    <ListItemIcon key={`icon-${i}`}>{icon}</ListItemIcon>
                  ))}
                  {option.label}
                </Grid>
              </ListItemText>
            </Link>
          ) : (
            <>
              {option.checked && <div style={{ width: 24 }}>{selected === i && <Check />}</div>}
              <ListItemText>
                <Grid item container xs={12} alignContent="center">
                  {(option.icons || []).map((icon, i) => (
                    <ListItemIcon key={`icon-${i}`}>{icon}</ListItemIcon>
                  ))}
                  {option.label}
                </Grid>
              </ListItemText>
            </>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ListMenu;
