import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

type CollapsibleDrawerListProps = {
  open: boolean;
  subMenu: any[];
};

function CollapsibleDrawerList(props: CollapsibleDrawerListProps) {
  const { open, subMenu } = props;

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List disablePadding>
        {subMenu.map((route, index) => (
          <Link
            to={route.path}
            style={{ textDecoration: "none", color: "inherit" }}
            key={route.path}
          >
            <ListItem disablePadding key={route.path}>
              <ListItemButton sx={{ pl: 4, py: 0.5 }}>
                <ListItemText
                  primary={route.title}
                  sx={{
                    "& span": {
                      position: "relative",
                      fontSize: "1.075rem",
                      "&:after": {
                        content: '""',
                        position: "absolute",
                        bottom: "-0.5rem",
                        left: "-1rem",
                        width: "calc(100% + 1rem)",
                        height: "1px",
                        backgroundColor: "var(--primary)",
                        display:
                          index === subMenu.length - 1 ? "block" : "none",
                      },
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Collapse>
  );
}

export default CollapsibleDrawerList;
