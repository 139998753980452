import React, { ComponentClass, ComponentType, FC, ReactElement, Suspense } from "react";
import { Switch, Route, Redirect, withRouter, RouteComponentProps, useLocation } from "react-router-dom";
import { pick, get } from "lodash";
import MetaTags from "../../components/MetaTags/MetaTags";
import Loading from "../../components/Loading/Loading";
import { useLanguage } from "../../locale/useLanguage";
import { useAuthorization } from "../../authorization/useAuthorization";
import { TranslationKeys } from "../../locale/localizationContext";
import { getFromLocalStorage, removeFromLocalStorage } from "../../helpers/localstorage";

export const type = {
  PUBLIC: "PUBLIC",
  SECURED: "SECURED",
  AUTHENTICATION: "AUTHENTICATION",
};

export type RouteType = {
  id: string | number;
  path: string;
  additionalPaths?: string[];
  fallbackPath?: string;
  type?: string;
  icon?: ReactElement | ComponentClass | ComponentType | string;
  exact?: boolean;
  redirectTo?: string;
  redirectToExternal?: string;
  children?: Array<any>;
  titleLocalizationKey: string;
  component?: any;
  noFollow?: boolean;
  layout?: any;
  layoutOptions?: object;
  section?: string;
  scopes?: Array<string>;
  showInMenu?: boolean;
};

export const getFlatRouteList = (routes: Array<RouteType>) => {
  const routeList: Array<RouteType> = [];
  routes.forEach((route) => {
    if (route.path) {
      routeList.push(route);
    }
    if (route.children && route.children.length > 0) {
      route.children.forEach((child) => {
        child.parent_id = route.id;
        if (child.path) {
          routeList.push(child);
        }
      });
    }
  });
  return routeList;
};

type RouteComponentType = {
  route: RouteType;
} & Record<string | number, any>;

const RouteComponent: React.FC<RouteComponentType> = ({ route, ...props }) => {
  const [t] = useLanguage();

  const title = route.titleLocalizationKey as TranslationKeys<string>;

  return (
    <>
      <MetaTags title={t(title)} noFollow={route.noFollow} />
      <Suspense fallback={<Loading />}>
        {route.layout ? (
          <route.layout
            {...{
              ...props,
              ...pick(route, ["id", "parent_id", "path", "title", "showInMenu"]),
              ...route.layoutOptions,
            }}
          >
            <route.component {...props} />
          </route.layout>
        ) : (
          <route.component {...props} />
        )}
      </Suspense>
    </>
  );
};

const Router: FC<
  {
    routes: Array<RouteType> | null;
    authorized: boolean;
    permissions?: Record<string, string[]>;
  } & RouteComponentProps
> = ({ routes = null, authorized = false, permissions = {} }) => {
  const [t] = useLanguage();
  const [auth] = useAuthorization();
  return routes ? (
    <>
      <Switch>
        {getFlatRouteList(routes).map((r: any, i) => (
          <Route
            key={i}
            path={[r.path, ...(r.additionalPaths || [])]}
            exact={r.exact || true}
            render={(props) => {
              // const { location, match } = props;
              // const { pathname, hash, search } = location;
              // const nextRedirectUri = `${pathname || match.url}${hash || ''}${
              //   search || ''
              // }`;
              const ls = getFromLocalStorage();
              const redirectUri = ls && (ls as any).redirectUri;
              // (r);
              // (r.path, !!r.component);
              if (r.redirectToExternal) {
                window.location = r.redirectToExternal;
                return <Loading />;
              } else if (r.redirectTo) {
                return <Redirect to={r.redirectTo} />;
              } else if (!authorized && r.type === type.SECURED) {
                return <Redirect to={`/login`} />;
              } else if (authorized && r.type === type.AUTHENTICATION) {
                removeFromLocalStorage("redirectUri");
                return <Redirect to={decodeURIComponent(redirectUri) || "/"} />;
              } else {
                if (!r.component) {
                  return <Loading />;
                } else {
                  if (r.type === type.SECURED && r.section) {
                    if (get(permissions, r.section, false)) {
                      return <RouteComponent route={r} {...props} />;
                    } else if (r.fallbackPath) {
                      return <Redirect to={r.fallbackPath} />;
                    } else {
                      return <Redirect to={"/forbidden"} />;
                    }
                    // return get(permissions, r.section, false) ? (
                    //   <RouteComponent route={r} {...props} />
                    // ) : (
                    //   // drawComponent(r, {
                    //   //   ...props,
                    //   //   section: r.section,
                    //   //   // permissions: (r.scopes || []).map((s: string) => ({
                    //   //   //   [s]: permissions[s],
                    //   //   // })),
                    //   // })
                    //   <Redirect to={"/forbidden"} />
                    // );
                  } else if (r.type === type.SECURED && r.scopes && r.scopes.length > 0) {
                    return (r.scopes || []).some((s: string) => Object.keys(permissions as object).some((p) => p === s)) ? (
                      <RouteComponent route={r} {...props} />
                    ) : (
                      // route(r, {
                      //   ...props,
                      //   permissions: (r.scopes || []).map((s: string) => ({
                      //     [s]: permissions[s],
                      //   })),
                      // })
                      <Redirect to={"/forbidden"} />
                    );
                  }
                  // return drawComponent(r, props);
                  return <RouteComponent route={r} {...props} />;
                }
              }
            }}
          />
        ))}

        <Route key={-1} render={() => <Redirect to={"/"} />} />
      </Switch>
    </>
  ) : (
    <p>{t("common.no_routes")}</p>
  );
};

export default withRouter(Router);

export const useQuery = () => {
  const { search } = useLocation();

  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  return Object.fromEntries(params);
};
