import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';

import './Loading.scss';

type LoadingProps = {
  fullPage?: boolean;
  style?: React.CSSProperties;
  circularProgressProps?: CircularProgressProps;
};

export const Loading: React.FC<LoadingProps> = ({
  fullPage = true,
  circularProgressProps = { size: 50, thickness: 10, color: 'primary' },
}) => {
  return (
    <div className={`loader ${fullPage ? 'full' : 'relative'}`}>
      {fullPage && <div className="loader-backdrop animation fadein" />}
      <CircularProgress {...circularProgressProps} />
    </div>
  );
};

export default Loading;
