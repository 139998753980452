import { customSecuredApi } from ".";
import Config from "../Config";
import ErrorAPI from "../helpers/api";

export const getProductListApi = async ({
  agencyId,
  familyId,
  modelId,
}: {
  agencyId: string;
  familyId?: string;
  modelId?: string;
}) =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.products.list.replace(":agencyId", agencyId)}${
      familyId ? `?productFamilyId=${familyId}` : ""
    }${modelId ? `&modelId=${modelId}` : ""}`,
  });

export const getProductFamilyListApi = async (agencyId: string) =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.products.families}?agencyId=${agencyId}&includeImage=true`,
  });

export const getFinancialDurationListApi = async () =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.products.duration}`,
  });

export const getAccessoryListApi = async ({
  agencyId,
  productModelId,
}: {
  agencyId: string;
  productModelId: string;
}) =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.products.accessories
      .replace(":agencyId", agencyId)
      .replace(":productModelId", productModelId)}`,
  });

export const getServicesListApi = async ({
  agencyId,
  productModelId,
}: {
  agencyId: string;
  productModelId: string;
}) =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.products.services
      .replace(":agencyId", agencyId)
      .replace(":productModelId", productModelId)}`,
  });

export const getPricesForCart = async ({
  agencyId,
  body,
}: {
  agencyId: string;
  body: any;
}) =>
  customSecuredApi.call({
    method: "POST",
    path: `${Config.apis.products.prices.replace(":agencyId", agencyId)}`,
    body: body,
  });

export const getTaxSavings = async ({
  agencyId,
  body,
}: {
  agencyId: string;
  body: any;
}) =>
  customSecuredApi.call({
    method: "POST",
    path: `${Config.apis.products.taxSavings.replace(":agencyId", agencyId)}`,
    body: body,
  });

export const createContractApi = async ({
  body,
  agencyId,
}: {
  body: any;
  agencyId: string;
}) => {
  const { payload, body: bodyApi } = await customSecuredApi.call({
    method: "POST",
    path: `${Config.apis.products.createContract.replace(
      ":agencyId",
      agencyId,
    )}`,
    body: body,
  });
  if (payload.ok) {
    return bodyApi;
  } else {
    throw new ErrorAPI(
      payload.status,
      ...bodyApi.errors.reduce((prev: any, curr: any) => prev + ` ${curr}`, ""),
    );
  }
};

export const approveContractApi = async ({
  agencyId,
  contractId,
}: {
  agencyId: string;
  contractId: string;
}) => {
  const { payload, body: bodyApi } = await customSecuredApi.call({
    method: "POST",
    path: `${Config.apis.products.approveContract
      .replace(":agencyId", agencyId)
      .replace(":contractId", contractId)}`,
  });
  if (payload.ok) {
    return bodyApi;
  }
  throw new ErrorAPI(
    payload.status,
    ...bodyApi.errors.reduce((prev: any, curr: any) => prev + ` ${curr}`, ""),
  );
};

export const getImageModel = async (modelId: string, iceCatId?: string) => {
  return customSecuredApi
    .call({
      method: "GET",
      path: `${Config.apis.icecat.content}?url=${encodeURIComponent(
        Config.apis.icecat.completeInfo.replace(
          ":codiceGTIN",
          "&GTIN=" + modelId,
        ),
      )}`,
    })
    .then(
      (res) => {
        return res.body.data;
      },
      (r) => {
        if (iceCatId) {
          return customSecuredApi
            .call({
              method: "GET",
              path: `${Config.apis.icecat.content}?url=${encodeURIComponent(
                Config.apis.icecat.completeInfo.replace(":codiceGTIN", "") +
                  "&icecat_id=" +
                  iceCatId,
              )}`,
            })
            .then((res) => {
              return res.body.data;
            });
        }
        return r;
      },
    );
};

export const GetImageUrl = (url: string) => {
  var l = `${Config.apiBaseurl}${
    Config.apis.icecat.gallery
  }?url=${encodeURIComponent(url)}`;
  return l;
};
