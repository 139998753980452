import React, { ReactElement, ReactFragment } from "react";
import { Button, Grid, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import CustomDialog from "../Dialog/Dialog";
import "./IconButtonMenu.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Check } from "@mui/icons-material";
import ListMenu from "../Menu/Menu";

// Global variables
const ITEM_HEIGHT = 48;

// Type model of the list of items to display
export type listItemMenu = {
  icons?: Array<ReactElement>;
  label: string;
  onClick?: () => void;
  checked?: boolean;
  redirectTo?: { pathname: string; state: { [key: string]: string } };
};

// Functional component
const IconButtonMenu = ({
  icon = <MoreVertIcon />,
  listItemMenu,
  className,
}: {
  icon?: ReactElement;
  listItemMenu?: Array<listItemMenu>;
  className?: string;
}) => {
  // useState
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const [selected, setSelected] = useState<number | undefined>(undefined);

  // Function that close the menù
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Return
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={`dots-button--button ${className}`}
        disableRipple
        disableFocusRipple
        disableTouchRipple
      >
        {icon}
      </IconButton>
      <ListMenu
        listItemMenu={listItemMenu}
        anchorEl={anchorEl}
        onRemoveAnchorEl={handleClose}
      />
    </div>
  );
};

export default IconButtonMenu;
