import _ from "lodash";
import dayjs from "dayjs";
// import log from 'helpers/logger';
// import appInfo from 'helpers/appInfo';
import { getFromLocalStorage } from "../helpers/localstorage";
// import { getCurrentLocale } from '.helpers/localization';

export interface ErrorAPIInterface {
  status: number | string;
  response?: unknown;
}

export interface ErrorResponse {
  errors: Array<string>;
}

export default class ErrorAPI extends Error {
  public status: number | string;
  public response?: unknown | ErrorResponse;

  constructor(status: number | string, response?: unknown) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(status.toString());

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorAPI);
    }

    this.status = status;
    this.response = response;
  }

  getStatus() {
    return this.status;
  }

  getResponse() {
    return this.response;
  }
}

export const headersBuilder = () => ({
  // "Accept-Language": getCurrentLocaleExtended(),
  // 'Accept-Language': getCurrentLocale(),
  "App-Launch-Count": _.get(getFromLocalStorage(), "app-launch-count", 1), //How many times the app was launched
  "App-Unique-Run-Id": _.get(getFromLocalStorage(), "app-run-id", null), //Unique Id to this concrete execution
  // 'App-Id': appInfo.name, //Packagename/Bundle Identifier
  // 'App-Build-Type': appInfo.env, //Debug/Release
  // 'App-Version-Code': appInfo.version, //Version Code
  "Device-OS": navigator.platform, //Operating System ios/android
  "Device-OS-Version": navigator.appVersion || navigator.userAgent, //Operating System Version
  "Device-Screen-Width": window.screen.availWidth, //Screen width in pixels
  "Device-Screen-Height": window.screen.availHeight, //Screen height in pixels
  // "Device-Push-Notifications-Enabled":
  //   _.get(Notification, "permission") === "granted" ? true : false, //true/false
  "Device-Screen-DPR": `${window.devicePixelRatio}x`, //DPR zoom !== DPI
  TimeOffset: dayjs().format("ZZ"), //Offset from UTC in Format +-HHMM (See ISO8601 Specs)
});

type ApiType = {
  baseUrl?: string;
  path: string;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  parse?: "JSON" | "BLOB" | "TEXT";
  uriParams?: object;
  upload?: boolean;
  body?: object | string | any;
  json?: boolean;
  headers?: Headers;
  credentials?: "include" | "same-origin" | "omit";
};

export type ApiInternalSetType = {
  body?: object | string | any;
  headers?: Headers;
  baseUrl?: string;
  path?: string;
};

export class Api {
  private baseUrl: string;
  private interceptor?: (
    params: ApiInternalSetType
  ) => ApiInternalSetType | Promise<ApiInternalSetType | undefined> | undefined;
  private errorHandler?: (error: ErrorAPI) => Promise<void> | void | undefined;

  constructor(
    baseUrl: string,
    interceptor?: (
      params: ApiInternalSetType
    ) => ApiInternalSetType | Promise<ApiInternalSetType | undefined> | undefined,
    errorHandler?: (error: ErrorAPI) => Promise<void> | void | undefined
  ) {
    this.baseUrl = baseUrl;
    this.interceptor = interceptor;
    this.errorHandler = errorHandler;
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  /**
   * @throws {ErrorAPI}
   */
  async call<BodyType>({
    baseUrl = this.baseUrl,
    path,
    method = "GET",
    parse = "JSON",
    // uriParams,
    upload = false,
    body = undefined,
    json = true,
    headers: customHeaders = new Headers(),
    credentials = "omit",
  }: ApiType): Promise<{ payload: Response; body?: BodyType | any }> {
    // eslint-disable-next-line no-undef
    let headers = new globalThis.Headers(customHeaders);
    if (this.interceptor) {
      const {
        body: newBody,
        headers: newHeaders,
        baseUrl: newBaseUrl,
        path: newPath,
      } = (await this.interceptor({
        body,
        headers,
        baseUrl,
        path,
      })) || {};
      baseUrl = newBaseUrl || baseUrl;
      path = newPath || path;
      body = newBody || body;
      headers = newHeaders || headers;
    }
    if (json) {
      headers.append("Content-Type", "application/json");
    }
    if (method === "POST" || method === "PATCH") {
      headers.append("X-Requested-With", "XMLHttpRequest");
    }

    let b = body?.toString();
    if (json) {
      b = JSON.stringify(body);
    }
    if (upload) {
      b = body;
    }

    try {
      const res = await fetch(`${baseUrl}${path}`, {
        method,
        body: b,
        headers,
        credentials,
      });
      const result: {
        payload: Response;
        body?: BodyType | string | object | any;
      } = {
        payload: res.clone(),
      };
      try {
        if (parse === "JSON") {
          result.body = await res.clone().json();
        } else if (parse === "BLOB") {
          result.body = await res.clone().blob();
        } else if (parse === "TEXT") {
          result.body = await res.clone().text();
        }
      } catch (e) {
        result.body = await res.clone().text();
        result.body = result.body.length > 0 ? result.body : undefined;
      }
      if (res.status >= 200 && res.status <= 399) {
        return result;
      }
      const error = new ErrorAPI(res.status, result.body);
      if (this.errorHandler) {
        await this.errorHandler(error);
      }
      throw error;
    } catch (e) {
      if (e instanceof ErrorAPI) {
        throw e;
      } else if (e instanceof Error) {
        throw new ErrorAPI(500, e.message);
      } else {
        throw e;
      }
    }
  }
}
