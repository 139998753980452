import { Box, Drawer, List } from "@mui/material";
import { RouteType } from "components/Router/Router";
import Config from "Config";
import { getCookie } from "helpers/cookie";
import { TranslationKeys } from "locale/localizationContext";
import { useLanguage } from "locale/useLanguage";
import { isEmpty } from "lodash";
import { RoleEnum } from "models/role.types";
import { Fragment, useEffect, useState } from "react";
import { config } from "../../../src/pages/stats/config";
import { routes } from "../../routes/routes";
import CloseDrawerButton from "./CloseDrawerButton";
import CollapsibleDrawerList from "./CollapsibleDrawerList";
import DrawerItem from "./DrawerItem";
import useCachedFamilies from "./useCachedFamilies";

type DrawerNavbarProps = {
  open: boolean;
  onClose: () => void;
};

function DrawerNavbar(props: DrawerNavbarProps) {
  const role = getCookie("role");
  const { open, onClose } = props;
  const [t] = useLanguage();
  const [menu, setMenu] = useState<RouteType[]>([]);
  const [openCollapsible, setOpenCollapsible] = useState({
    products: false,
    stats: false,
  });
  const { families } = useCachedFamilies();
  const subMenus = {
    products: families.map((family) => ({
      path: `/products/${family.id}`,
      title: family.name,
    })),
    stats: Object.keys(config).map((key) => ({
      path: `/stats/${key}`,
      title: t(
        `pages.stats.stats_type.${key}.title` as TranslationKeys<string>,
      ),
    })),
  };

  useEffect(() => {
    if (!routes || isEmpty(routes)) {
      return;
    }

    const permittedRoutes = routes
      .filter((route) => route.showInMenu)
      .filter((route) => {
        const { roleScopes } = Config;
        let userRolePermissions = {};
        switch (role || "") {
          case RoleEnum.SUPERADMINISTRATOR:
            userRolePermissions = roleScopes.SUPERADMINISTRATOR;
            break;
          case RoleEnum.ADMINISTRATOR:
            userRolePermissions = roleScopes.ADMINISTRATOR;
            break;
          case RoleEnum.AGENT:
            userRolePermissions = roleScopes.AGENT;
            break;
          case RoleEnum.AGENCY:
            userRolePermissions = roleScopes.AGENCY;
            break;
          case RoleEnum.AGENCY_ADMIN:
            userRolePermissions = roleScopes.AGENCY_ADMIN;
            break;
          case RoleEnum.CAPO_AREA:
            userRolePermissions = roleScopes.CAPO_AREA;
            break;
          case RoleEnum.GROUP_MANAGER:
            userRolePermissions = roleScopes.GROUP_MANAGER;
            break;
          case RoleEnum.CUSTOMER:
            userRolePermissions = roleScopes.CUSTOMER;
            break;
          default:
            break;
        }
        let section = route.section;
        let permittedSection = Object.keys(userRolePermissions);
        return section !== undefined
          ? permittedSection.includes(section)
          : false;
      });

    setMenu(permittedRoutes);
  }, [role]);

  return (
    <Drawer anchor={"left"} open={open} onClose={() => onClose()}>
      <Box sx={{ width: 250, pt: "4rem" }} role="presentation">
        <List sx={{ p: 0 }}>
          <CloseDrawerButton
            onClick={() => {
              onClose();
            }}
          />
          {menu.map((route) => {
            if (!route.showInMenu) return null;
            const title = route.titleLocalizationKey as TranslationKeys<string>;
            const routeId = route.id as keyof typeof openCollapsible;
            const hasSubMenu = routeId === "stats" || routeId === "products";

            return (
              <Fragment key={route.id}>
                <DrawerItem
                  route={route}
                  title={t(title)}
                  hasSubMenu={hasSubMenu}
                  {...(hasSubMenu && {
                    setOpen: () => {
                      setOpenCollapsible({
                        ...openCollapsible,
                        [routeId]: !openCollapsible[routeId],
                      });
                    },
                    isSubMenuOpen: openCollapsible[routeId],
                  })}
                />
                {hasSubMenu ? (
                  <CollapsibleDrawerList
                    open={openCollapsible[routeId]}
                    subMenu={subMenus[routeId]}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}

export default DrawerNavbar;
