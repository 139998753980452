import React from "react";
// import firebase from "firebaseApp";
import { getUserInfoApi, logoutApi } from "../api/auth.api";
import ErrorAPI from "../helpers/api";
import { getFromLocalStorage, setOnLocalStorage, removeFromLocalStorage } from "../helpers/localstorage";
import Config from "../Config";

export interface AuthPropType {
  user?: any;
  permissions?: Record<string, string[]>;
}

export type SetAuthPropType = (params: any) => void;

interface AuthorizationContextProps {
  auth: AuthPropType;
  setAuth: SetAuthPropType;
}

interface AuthorizationProviderProps {
  children: React.ReactNode;
  loader?: React.ReactNode;
}

export const AuthorizationContext = React.createContext<AuthorizationContextProps>({
  auth: {},
  setAuth: () => null,
});

export const AuthorizationProvider = ({ children }: any) => {
  const [auth, setAuth] = React.useState<AuthPropType>({});
  // firebase.auth().onAuthStateChanged(async (auth) => {
  //   try {
  //     if (auth) {
  //       const { token: previousToken } = getFromLocalStorage(undefined);
  //       const token = await auth.getIdToken();
  //       const user = await getUserInfoApi();
  //       // TODO get role and compute scopes
  //       // let permissions;
  //       if (user.userRole) {
  //         setOnLocalStorage({ token });
  //         setOnLocalStorage({ user });
  //       } else {
  //         throw new ErrorAPI(401, "unauthorized");
  //       }
  //       // setAuth({ user, permissions });
  //       if (!previousToken) {
  //         window.location.href = "/";
  //       }
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //     } else {
  //       // User is signed out
  //       // ...
  //       removeFromLocalStorage("token");
  //       removeFromLocalStorage("user");
  //     }
  //   } catch (e) {
  //     if (e instanceof ErrorAPI) {
  //       await logoutApi(false);
  //       removeFromLocalStorage("token");
  //       removeFromLocalStorage("user");
  //       window.location.href = "/forbidden";
  //     } else {
  //       throw e;
  //     }
  //   }
  // });
  return (
    <AuthorizationContext.Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
