type Config = {
  [key: string]: {
    [key: string]: {
      focuses: ("all" | "annual" | "perc")[];
      filters: (
        | "status"
        | "agency"
        | "provider"
        | "family"
        | "sort"
        | "accessory"
      )[];
      table: "normal" | "vertical";
    };
  };
};

export const config: Config = {
  "overall-performance": {
    "1": {
      focuses: ["annual", "all"],
      filters: ["status", "agency"],
      table: "normal",
    },
    "2": {
      focuses: ["annual", "all"],
      filters: ["status", "agency"],
      table: "normal",
    },
    "3": {
      focuses: ["annual", "all"],
      filters: ["status"],
      table: "normal",
    },
    "4": {
      focuses: ["annual", "all"],
      filters: ["status"],
      table: "normal",
    },
    "5": {
      focuses: ["annual", "all"],
      filters: ["agency"],
      table: "normal",
    },
    "6": {
      focuses: ["perc", "all"],
      filters: ["agency"],
      table: "vertical",
    },
  },
  "efficiency-metrics": {
    "1": {
      focuses: ["annual"],
      filters: ["provider"],
      table: "normal",
    },
    "2": {
      focuses: ["all"],
      filters: [],
      table: "normal",
    },
  },
  "sales-performance": {
    "1": {
      focuses: ["annual", "all"],
      filters: ["status", "agency"],
      table: "normal",
    },
    "2": {
      focuses: ["annual", "all"],
      filters: [],
      table: "normal",
    },
  },
  "ranking-products": {
    "1": {
      focuses: [],
      filters: ["sort", "family", "agency"],
      table: "vertical",
    },
    "2": {
      focuses: [],
      filters: ["sort", "accessory", "agency"],
      table: "vertical",
    },
  },
  "user-engagement": {
    "1": {
      focuses: ["all"],
      filters: ["agency"],
      table: "vertical",
    },
  },
};

export const statsPages = {
  "overall-performance": [
    "Numero totale di ordini inseriti, esclusi preventivi",
    "Percentuale di ordini accettati rispetto al totale",
    "Valore ordine medio globale",
    "Valore ordine medio accettati",
    "Provvigione agenzia",
    "Percentuale modalità di canone per ordine globale",
  ],
  "efficiency-metrics": [
    "Tempo medio per approvazione pratica (gg)",
    "Tempo medio per spedizione merce (gg)",
  ],
  "sales-performance": [
    "Valore ordini generato dall'agenzia",
    "Up-selling effectiveness accessori e assistenze",
  ],
  "ranking-products": [
    "Classifica per model_id per i più venduti",
    "Classifica per model_id per Accessori e Servizi",
  ],
  "user-engagement": ["Numero di accessi al portale"],
};
